@use 'sass:map';

@use '@shared/styles/config/variables' as *;
@use '@shared/styles/mixins/breakpoint' as *;
@use '@shared/styles/mixins/spacing' as *;

.root {
  &:last-child {
    padding-bottom: map.get($spacers, 'spacing', '7');

    @include breakpoint('lg', 'min') {
      padding-bottom: map.get($spacers, 'spacing', '12');
    }

    @include breakpoint('xl', 'min') {
      padding-bottom: map.get($spacers, 'spacing', '15');
    }
  }
}

.hero {
  background-color: map.get($colors, 'lily-of-the-valley');
  overflow: hidden;
  padding: map.get($spacers, 'spacing', '7') 0;
  position: relative;

  @include breakpoint('lg', 'min') {
    padding: map.get($spacers, 'spacing', '12') 0;
    text-align: center;
  }

  &::before {
    background-color: map.get($colors, 'purple-rain-tint');
    border-radius: map.get($radiuses, '7') 0 0 map.get($radiuses, '7');
    bottom: 0;
    content: '';
    left: 50%;
    min-width: 62.5rem;
    position: absolute;
    right: 0;
    top: 0;
  }

  .title {
    @include spacing('md', 'bottom', 'margin');
  }

  > * {
    position: relative;
  }
}

.posts {
  &:first-child {
    padding-top: map.get($spacers, 'spacing', '7');

    @include breakpoint('lg', 'min') {
      padding-top: map.get($spacers, 'spacing', '12');
    }

    @include breakpoint('xl', 'min') {
      padding-top: map.get($spacers, 'spacing', '15');
    }
  }
}
